<template lang="pug">
  .FormDocumentsValidationInfo
    div.input-container.m-3.mx-12
      .flex.items-center
        input-component(
          :value="formSelected.number || formSelected.numero"
          label="Fórmula No."
          id="formSelected"
          name="formSelected"
          type="text"
          mode="input"
          appendMore
          required
          readonly
        ).borderDanger
        button(
          type="button"
        ).block.m-4.my-0.mt-4.Arrow.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
          span.material-icons-outlined.text-primary_other image
        div.block.mx-0.my-0.mt-4.Arrow.bg-danger_background.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
          span.material-icons-outlined.text-danger_color warning_amber
    div.rounded-bd-small.bg-danger_background.p-3.py-2.flex.m-3.mx-12.mt-8
      span.material-icons-outlined.text-danger_color.mr-2 warning_amber
      p.leading-4: small.text-danger_color
        | {{ formSelected.resultado_descripcion }}
    div.rounded-bd-small.border.border-orange-600.p-3.py-2.flex.m-3.mx-12.mt-8(v-if="formSelected.resultado == 3" style="border-color: rgb(217 119 6);")
      p.leading-4: small
        | En caso que una misma fórmula tenga más de una entrega, favor corregir número digitando al final la entrega a la que pertenece. Ejemplo: 9991, 9992, 9993
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
      button-component.font-semibold(
        text="Corregir número"
        :onClick="goToForms"
        themeSelected="light"
        class="hover:bg-secondary hover:text-white hover:font-thin"
      ).mb-5.btn.transition.duration-300
      button-component.font-semibold(
        :onClick="questionDelete"
        text="Eliminar número"
        themeSelected="light"
        class="hover:bg-secondary hover:text-white hover:font-thin"
      ).mb-5.btn.transition.duration-300
      button-component.font-semibold(
        v-if="formSelected.numero == 2 || formSelected.resultado == 5"
        :onClick="handleContinue"
        text="Continuar con este número"
        themeSelected="light"
        class="hover:bg-secondary hover:text-white hover:font-thin"
      ).mb-5.btn.transition.duration-300
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import loadingMessage from '../utils/loadingMessage';

export default {
  name: "FormDocumentsValidationInfo",
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
  },
  computed: {
    ...mapState({
      formSelected: (state) => state.root.formSelected,
      formDocuments: (state) => state.formDocuments.formDocumentsState,
    }),
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal"
    ]),
    ...mapActions("formDocuments", [
      "setFormDocuments",
    ]),
    ...mapMutations("formDocuments", {
      setStateFormDocuments: "setState",
    }),
    ...mapActions("drugstore", [
      "fetchDrugstoresList",
    ]),
    goToForms() {
      this.setStateFormDocuments({ key: "formSelectedForEdit", value: this.formSelected, });
      this.changeTrip("FormDocumentsAdd"); // FIXME: Test this
    },
    questionDelete() {
      this.handleShowModal(loadingMessage({
        title: "Seguro que desea eliminar esta fórmula",
        state: "danger",
        otherButtonCancelDelete: true,
        onClickDelete: this.deleteForm,
      }));
    },
    deleteForm() {
      if (this.formDocuments.length === 1) {
        this.setFormDocuments([]);
        return;
      }
      const filteredDocuments = this.formDocuments.filter((formDocumentScope) => formDocumentScope.id !== this.formSelected.id);

      this.setFormDocuments(filteredDocuments || []);
      this.changeTrip("FormDocumentsValidation");
    },
    handleContinue() {
      this.fetchDrugstoresList();
      this.changeTrip("Home_appointments");
    },
  },
}
</script>

<style scoped>
.borderDanger {
  border-bottom: 2px solid rgba(231, 74, 122);
}

.borderDanger .labelStylesActive {
  color: rgba(231, 74, 122);
}

.FormDocumentsValidationInfo {
  padding-bottom: 15rem;
  overflow: auto;
}
</style>